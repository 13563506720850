/* eslint-disable */
import {
    Icon, IconButton
} from '@edx/paragon';

const SvgMail = props => <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" x="0.696777" y="0.568848" width="19.613" height="19.613" fill="#1A1A1A" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.2236 3.83777H4.78268C3.42868 3.83777 2.33105 4.9354 2.33105 6.28939V14.4615C2.33105 15.8155 3.42868 16.9131 4.78268 16.9131H16.2236C17.5776 16.9131 18.6752 15.8155 18.6752 14.4615V6.28939C18.6752 4.9354 17.5776 3.83777 16.2236 3.83777ZM16.2237 5.47215L10.9118 9.12507C10.659 9.27105 10.3475 9.27105 10.0946 9.12507L4.78279 5.47215H16.2237Z" fill="#1A1A1A" />
</svg>

const MailIcon = (
    <IconButton
        disabled={true}
        name="passwordIcon"
        src={SvgMail}
        iconAs={Icon}
        size="sm"
        variant="secondary"
        alt={"Mail Icon"}
    />
);

export default MailIcon