/* eslint-disable */
import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink, Image } from '@edx/paragon';
import classNames from 'classnames';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import messages from './messages';

const LargeLayout = () => {
    const { formatMessage } = useIntl();

    return (
        <Row className="r4c_login_left_container">
            <Col>
                <Hyperlink destination={getConfig().MARKETING_SITE_BASE_URL}>
                    <Image className="r4c_logo" alt={getConfig().SITE_NAME} src={getConfig().LOGO_WHITE_URL} />
                </Hyperlink>
                <h1 className="r4c_login_header">Start learning with RES4CITY</h1>
                <p className="r4c_login_p">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </Col>
        </Row>
    );

    //   return (
    //     <div className="w-50 d-flex">
    //       <div className="col-md-9 bg-primary-400">
    //         <Hyperlink destination={getConfig().MARKETING_SITE_BASE_URL}>
    //           <Image className="logo position-absolute" alt={getConfig().SITE_NAME} src={getConfig().LOGO_WHITE_URL} />
    //         </Hyperlink>
    //         <div className="min-vh-100 d-flex align-items-center">
    //           <div className={classNames({ 'large-yellow-line mr-n4.5': getConfig().SITE_NAME === 'edX' })} />
    //           <h1
    //             className={classNames(
    //               'display-2 text-white mw-xs',
    //               { 'ml-6': getConfig().SITE_NAME !== 'edX' },
    //             )}
    //           >
    //             {formatMessage(messages['start.learning'])}
    //             <div className="text-accent-a">
    //               {formatMessage(messages['with.site.name'], { siteName: getConfig().SITE_NAME })}
    //             </div>
    //           </h1>
    //         </div>
    //       </div>
    //       <div className="col-md-3 bg-white p-0">
    //         <svg className="ml-n1 w-100 h-100 large-screen-svg-primary" preserveAspectRatio="xMaxYMin meet">
    //           <g transform="skewX(171.6)">
    //             <rect x="0" y="0" height="100%" width="100%" />
    //           </g>
    //         </svg>
    //       </div>
    //     </div>
    //   );
};

export default LargeLayout;
