/* eslint-disable */
import {
    Icon, IconButton
} from '@edx/paragon';

const SvgAvatar = props => <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.01" x="0.696777" y="0.961304" width="19.613" height="19.613" />
    <path style={{ fill: "black" }} d="M10.5032 9.95063C12.3085 9.95063 13.772 8.48712 13.772 6.6818C13.772 4.87647 12.3085 3.41296 10.5032 3.41296C8.69788 3.41296 7.23438 4.87647 7.23438 6.6818C7.23438 8.48712 8.69788 9.95063 10.5032 9.95063Z" />
    <path style={{ fill: "black" }} d="M15.4064 18.1227C15.8578 18.1227 16.2236 17.7569 16.2236 17.3055C16.2236 14.1462 13.6625 11.5851 10.5032 11.5851C7.34385 11.5851 4.78271 14.1462 4.78271 17.3055C4.78271 17.7569 5.14859 18.1227 5.59992 18.1227H15.4064Z" />
</svg>

const AvatarIcon = (
    <IconButton
        disabled={true}
        name="passwordIcon"
        src={SvgAvatar}
        iconAs={Icon}
        size="sm"
        variant="secondary"
        alt={"Avatar Icon"}
    />
);

export default AvatarIcon